<template>
  <div>
    <location />
    <div class="page xpoint">
      <tab />
      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-4"
          role="tabpanel"
          aria-labelledby="pills-4-tab"
        >
          <div class="wrap-1200">
            <div class="row row-cols-1 row-cols-md-2 g-0">
              <!-- Reward Points -->
              <div class="col pe-md-2 pb-2 pb-md-0">
                <h5 class="mb-3">{{ $t("text.common.xpoint_balance") }}</h5>
                <div class="row row-cols-2 g-0 balance-area">
                  <div class="col">
                    <div class="card h-100">
                      <div class="card-body d-table">
                        <div class="d-table-cell align-middle">
                          <p class="card-title">
                            <a
                              @click="$bvToast.show('toast-opoint-info')"
                              class="pointer"
                            >
                              {{ $t("title.common.oom_point") }}
                              <span
                                class="material-icons-outlined align-text-top"
                                >info</span
                              >
                            </a>
                          </p>
                          <h2>
                            {{
                              player_info &&
                              player_info.player_oom_point | comma | suffix("P")
                            }}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card h-100 bg-cyan">
                      <div class="card-body d-table">
                        <div class="d-table-cell align-middle">
                          <p class="card-title">
                            <a
                              @click="$bvToast.show('toast-spoint-info')"
                              class="pointer"
                            >
                              {{ $t("title.common.standard_point") }}
                              <span
                                class="material-icons-outlined align-text-top"
                                >info</span
                              >
                            </a>
                          </p>
                          <h2>
                            {{
                              player_info &&
                              player_info.player_standard_point
                                | comma
                                | suffix("P")
                            }}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- X-Badge -->
              <div class="col pt-4 pt-md-0 ps-md-2">
                <h5 class="mb-3">{{ $t("text.common.xbadge") }}</h5>
                <swiper class="badge-area border" :options="swiperOptions">
                  <swiper-slide
                    v-for="(item, index) in badge_list"
                    :key="'b' + index"
                  >
                    <a
                      class="avatar avatar-xl"
                      @click="toast_xbadge_info(item)"
                    >
                      <!-- Current representative badge -->
                      <span
                        class="material-icons avatar-active"
                        v-if="item.badge_pk == player_info.badge_fk"
                        >done</span
                      >

                      <img
                        :class="
                          playerbadge_list.find(
                            (v) => v.badge_fk == item.badge_pk
                          )
                            ? ''
                            : 'avatar-unavailable'
                        "
                        :src="item.filename | get_img"
                      />
                      <!-- Avatar content defaults to the 'grade' icon when no other content is specified -->
                      <!-- <span class="material-icons default-icon-grade">grade</span> -->
                    </a>
                  </swiper-slide>
                  <div class="swiper-scrollbar" slot="scrollbar"></div>
                </swiper>
              </div>
            </div>

            <b-form @submit="onSubmit" class="filter-group mt-4 pt-2">
              <div
                class="row row-cols-1 row-cols-md-2 g-2 flex-grow-1 flex-md-grow-0 ms-md-auto"
              >
                <div class="col date-search">
                  <input type="date" v-model="searchData.startdate" />
                  <input type="date" v-model="searchData.enddate" />
                </div>

                <div class="col">
                  <div class="row g-2">
                    <div class="col">
                      <select v-model="searchData.pagesize">
                        >
                        <option value="10">
                          {{ $t("filter.common.sort_by_10") }}
                        </option>
                        <option value="30">
                          {{ $t("filter.common.sort_by_30") }}
                        </option>
                        <option value="50">
                          {{ $t("filter.common.sort_by_50") }}
                        </option>
                        <option value="100">
                          {{ $t("filter.common.sort_by_100") }}
                        </option>
                      </select>
                    </div>
                    <div class="col col-md-auto">
                      <div class="input-group">
                        <select
                          name="type"
                          class="form-select"
                          v-model="searchData.pointtype"
                        >
                          <option value="0">
                            {{ $t("filter.common.total") }}
                          </option>
                          <option value="1">
                            {{ $t("title.common.oom_point") }}
                          </option>
                          <option value="2">
                            {{ $t("title.common.standard_point") }}
                          </option>
                        </select>
                        <button
                          class="btn btn-primary btn-md icon-search"
                          type="submit"
                        ></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-form>

            <div
              class="xpoint-history border-top-dgray"
              v-if="items.length > 0"
            >
              <div
                class="row g-0 align-items-center border-bottom"
                v-for="(item, index) in items"
                :key="'a' + index"
              >
                <div class="col-8 col-md-6">
                  <h6>{{ item.poi_related.title }}</h6>
                </div>
                <div
                  class="col-4 col-md-3 text-end"
                  v-if="item.poi_type == 'oom'"
                >
                  <h6 class="text-point-op" title="OOM Point">
                    + {{ item.poi_point || comma | suffix("P") }}
                  </h6>
                  <p class="text-point-op" title="OOM Point Balance">
                    {{ $t("text.common.tot") }}
                    {{ item.player_oom_point || comma | suffix("P") }}
                  </p>
                </div>
                <div
                  class="col-4 col-md-3 text-end"
                  v-if="item.poi_type == 'standard'"
                >
                  <h6 class="text-point-sp" title="standard Point">
                    + {{ item.poi_point || comma | suffix("P") }}
                  </h6>
                  <p class="text-point-sp" title="standard Point Balance">
                    {{ $t("text.common.tot") }}
                    {{ item.player_standard_point || comma | suffix("P") }}
                  </p>
                </div>
                <div class="col-12 col-md-3 pt-2 pt-md-0">
                  <div class="d-flex flex-md-column align-items-md-end">
                    <p
                      class="text-date"
                      v-if="item.timezone_regdate.timezone_private"
                    >
                      <dfn
                        class="time-zone-mark badge rounded-pill bg-orange"
                        title="Connect time"
                        >CT</dfn
                      >
                      {{ item.timezone_regdate.timezone_private | dateformat }}
                    </p>
                    <p
                      class="text-date"
                      v-else-if="item.timezone_regdate.timezone_common"
                    >
                      <dfn
                        class="time-zone-mark badge rounded-pill bg-green"
                        title="Standard time"
                        >ST</dfn
                      >
                      {{ item.timezone_regdate.timezone_common | dateformat }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            
            <div v-else class="xpoint-history border-top-dgray">
              <p class=" border-bottom text-center p-3">
                {{ $t("text.common.no_data") }}
              </p>
            </div>
                     
          </div>
          <!-- // wrap-1200 -->
        </div>
      </div>
    </div>

    <b-toast
      id="toast-opoint-info"
      toaster="position-fixed top-50 start-50 translate-middle"
      no-auto-hide
      solid
    >
      <template #toast-title>
        {{ $t("title.common.oom_point") }}
      </template>
      <p>{{ $t("text.halloffame.sub_title_1") }}</p>
      <p class="mt-2">{{ $t("text.halloffame.sub_title_3") }}</p>
      <div class="btn-bottom-wrapper">
        <b-button
          size="sm"
          variant="secondary"
          :aria-label="$t('button.common.learn_more')"
          @click="$router.push({ name: 'PointInfo', query: { pointtype: 1 } })"
        >
          {{ $t("button.common.learn_more") }}
        </b-button>
      </div>
    </b-toast>

    <b-toast
      id="toast-spoint-info"
      toaster="position-fixed top-50 start-50 translate-middle"
      no-auto-hide
      solid
    >
      <template #toast-title>
        {{ $t("title.common.standard_point") }}
      </template>
      <p>{{ $t("text.halloffame.sub_title_6") }}</p>
      <p class="mt-2">{{ $t("text.halloffame.sub_title_7") }}</p>
      <div class="btn-bottom-wrapper">
        <b-button
          size="sm"
          variant="secondary"
          :aria-label="$t('button.common.learn_more')"
          @click="$router.push({ name: 'PointInfo', query: { pointtype: 2 } })"
        >
          {{ $t("button.common.learn_more") }}
        </b-button>
      </div>
    </b-toast>

    <b-toast
      id="toast-xbadge-info"
      toaster="position-fixed top-50 start-50 translate-middle"
      body-class="pt-0 text-center"
      no-auto-hide
      solid
    >
      <template #toast-title>
        {{ $t("text.common.xbadge_info") }}
      </template>
      <div class="card justify-content-center my-3 p-4 bg-gray border-0">
      <!--         
        <div class="p-1 border border-5">
          <div class="avatar avatar-xxxl">
            <span
              class="material-icons avatar-active p-1 fw-bold"
              v-if="xbadge_info && xbadge_info.badge_pk == player_info.badge_fk"
              >done</span
            >
            <img :src="xbadge_info && xbadge_info.filename2 | get_img" />
          </div>
        </div>
        -->
        <div class="badge-full-img bg-pink-">
          <span
            class="material-icons avatar-active fw-bold"
            v-if="xbadge_info && xbadge_info.badge_pk == player_info.badge_fk"
            >done</span
          >
          <img :src="xbadge_info && xbadge_info.filename2 | get_img">
        </div>        
      </div>

      <p class="sub-title">{{ xbadge_info && xbadge_info.title }}</p>
      <p class="mb-2">
        {{ xbadge_info && xbadge_info.memo }}
      </p>
      <div class="btn-bottom-wrapper">
        <b-button
          size="sm"
          variant="outline-secondary"
          :aria-label="$t('button.common.close')"
          @click="$bvToast.hide('toast-xbadge-info')"
        >
          {{ $t("button.common.close") }}
        </b-button>
        <b-button
          v-if="
            xbadge_info &&
            playerbadge_list.find((v) => v.badge_fk == xbadge_info.badge_pk) &&
            xbadge_info.badge_pk !== player_info.badge_fk
          "
          size="sm"
          variant="secondary"
          :aria-label="$t('button.common.change')"
          @click="badge_chg(xbadge_info && xbadge_info.badge_pk)"
        >
          {{ $t("button.common.change") }}
        </b-button>

        <b-button
          v-if="
            xbadge_info &&
            playerbadge_list.find((v) => v.badge_fk == xbadge_info.badge_pk) &&
            xbadge_info.badge_pk == player_info.badge_fk
          "
          size="sm"
          variant="secondary"
          :aria-label="$t('button.common.remove')"
          @click="badge_remove()"
        >
          {{ $t("button.common.remove") }}
        </b-button>
      </div>
    </b-toast>
  </div>
</template>

<script>
import location from "@/components/Location";
import tab from "@/components/membership/Tab";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import ax from "@/api/membership";

export default {
  name: "MyXpoint",
  components: {
    location,
    tab,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      searchData: {
        pointtype:
          this.$route.query.pointtype ||
          (this.$store.state.searchData[this.$route.matched[0].name] &&
            this.$store.state.searchData[this.$route.matched[0].name]
              .pointtype) ||
          "0",
        pageno:
          this.$route.query.pageno ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].pageno) ||
          1,
        startdate:
          this.$route.query.startdate ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].startdate) ||
          this.$moment().add(-1, "month").format("YYYY-MM-DD"),
        enddate:
          this.$route.query.enddate ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].enddate) ||
          this.$moment().format("YYYY-MM-DD"),
        pagesize:
          this.$route.query.pagesize ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].pagesize) ||
          10,
      },
      player_info: null,
      items: [],
      badge_list: [],
      playerbadge_list: [],
      pointlistcnt: 0,
      xbadge_info: null,
      swiperOptions: {
        slidesPerView: 4.5,
        /* spaceBetween: 16, */

        breakpoints: {
          400: {
            slidesPerView: 5,
          },
          576: {
            slidesPerView: 6.5,
          },
          768: {
            slidesPerView: 5,
          },
          992: {
            slidesPerView: 6,
          },
          1024: {
            slidesPerView: 6.5,
          },
          1200: {
            slidesPerView: 7,
          },
          1400: {
            slidesPerView: 7.5,
          },
        },
        scrollbar: {
          el: ".swiper-scrollbar",
          hide: false,
        },
        /* autoplay: {
          delay: 2000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true
        }, */
      },
    };
  },
  methods: {
    badge_chg: function (badge_pk) {
      ax.set_badge(badge_pk, (flag, data) => {
        if (flag) {
          this.get_list();
        } else {
          alert(data.message);
        }
        this.$bvToast.hide("toast-xbadge-info");
      });
    },

    badge_remove: function () {
      ax.remove_badge((flag, data) => {
        if (flag) {
          this.get_list();
        } else {
          alert(data.message);
        }
        this.$bvToast.hide("toast-xbadge-info");
      });
    },

    toast_xbadge_info: function (item) {
      this.xbadge_info = item;
      this.$bvToast.show("toast-xbadge-info");
    },

    get_list() {
      this.$store.commit("setSearchData", {
        [this.$route.name]: this.searchData,
      });
      ax.get_playerpointlist(this.searchData, (flag, data, code = "") => {
        this.total_cnt = data.pointlistcnt;
        this.page_total = Math.ceil(data.pointlistcnt / this.pagesize);
        this.items = data.pointlist;
        this.badge_list = data.badge_list;
        this.playerbadge_list = data.playerbadge_list;
        this.player_info = data.player_info;

        if (!flag) alert(data.message);
        if (code === 401) {
          this.$store.dispatch("logout");
          this.$router.push("/");
        }
        // alert(data);
      });
    },
    onSubmit(event) {
      event.preventDefault();
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
  },
  created() {
    this.get_list();
  },
  watch: {
    "searchData.pointtype": function () {
      this.searchData.pageno = 1;
      this.get_list();
    },
    "searchData.startdate": function () {
      this.searchData.pageno = 1;
      this.get_list();
    },
    "searchData.enddate": function () {
      this.searchData.pageno = 1;
      this.get_list();
    },
    "searchData.pageno": function () {
      this.get_list();
    },
  },
};
</script>