<template>
  <div>
    <location />
    <div class="page">
      <tab />
      <div class="tab-content wrap-1200" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-2"
          role="tabpanel"
          aria-labelledby="pills-2-tab"
        >
          <div class="tourn-banner my-4">
            <div class="card flex-md-row justify-content-md-between">
              <div class="col-md-4 card-img-wrapper order-md-2">
                <img
                  :src="
                    tourbinary &&
                    tourbinary[0] &&
                    tourbinary[0].filepath
                      | get_img(
                        'https://krstorage.s3.ap-northeast-2.amazonaws.com/files/tournament/06edda88fe1bb993db760ee25b8ad9fa.jpg'
                      )
                  "
                />
              </div>

              <div class="col-md-8 d-flex flex-column order-md-1">
                <div class="card-body">
                  <span class="tourn-type badge rounded-pill"
                    >{{ tournament_type(tournamentinfo.type) }} /
                    {{ tournamentinfo.tour_rank_type | tour_rank_type }}</span
                  >
                  <h3 class="card-title">
                    {{ tournamentinfo.title }}
                  </h3>
                </div>

                <div class="card-footer">
                  <div class="row gx-2 gy-1">
                    <div
                      class="col-auto text-date"
                      v-if="
                        tournamentinfo.timezone_startdate &&
                        tournamentinfo.timezone_startdate.timezone_private
                      "
                    >
                      <!-- 사용자 시간 -->
                      <dfn
                        class="time-zone-mark badge rounded-pill bg-orange"
                        title="Connect time"
                        >CT</dfn
                      >
                      {{
                        tournamentinfo.timezone_startdate &&
                        tournamentinfo.timezone_startdate.timezone_private
                          | dateformat
                      }}
                      ~
                      {{
                        tournamentinfo.timezone_enddate &&
                        tournamentinfo.timezone_enddate.timezone_private
                          | dateformat
                      }}
                    </div>
                    <div
                      class="col-auto text-date"
                      v-else-if="
                        tournamentinfo.timezone_startdate &&
                        tournamentinfo.timezone_startdate.timezone_common
                      "
                    >
                      <!-- 서비스 시간 -->
                      <dfn
                        class="time-zone-mark badge rounded-pill bg-green"
                        title="Standard time"
                        >ST</dfn
                      >
                      {{
                        tournamentinfo.timezone_startdate &&
                        tournamentinfo.timezone_startdate.timezone_common
                          | dateformat
                      }}
                      ~
                      {{
                        tournamentinfo.timezone_enddate &&
                        tournamentinfo.timezone_enddate.timezone_common
                          | dateformat
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="border-top-dgray mt-4">
            <div class="row g-0 border-bottom">
              <div
                class="col-4 col-lg-2 bg-gray row g-0 g-0 align-items-center"
              >
                <label class="d-table-cell">{{
                  $t("title.common.information")
                }}</label>
              </div>
              <div class="col-8 col-lg-10 d-flex">
                <p class="d-table-cell align-self-center">
                  {{ tournamentinfo.remark }}
                </p>
              </div>
            </div>

            <div class="row g-0 border-bottom">
              <div
                class="col-4 col-lg-2 bg-gray row g-0 g-0 align-items-center"
              >
                <label class="d-table-cell">{{
                  $t("title.common.course")
                }}</label>
              </div>
              <div class="col-8 col-lg-10">
                <p class="d-table-cell">
                  <span v-for="(course, index) in tournamentcourse" :key="index"
                    >{{ index === 0 ? "" : ", " }}{{ course.coursename }}</span
                  >
                </p>
              </div>
            </div>

            <div class="row g-0 border-bottom">
              <div class="row g-0 col-4 col-lg-2 bg-gray align-item-center">
                <label class="d-table-cell align-self-center">{{
                  $t("title.common.subcourse")
                }}</label>
              </div>
              <div v-if="subcourselistcnt" class="col-8 col-lg-10">
                <div
                  v-for="(anc, index1) in subcourselist"
                  :key="'k_' + index1"
                  class="subcourse"
                >
                  <h6>{{ anc.subcoursename }}</h6>
                  <small class="d-block text-dgray"
                    >{{ $t("text.common.subcourse") }}
                    {{ anc.subcourseseq }}</small
                  >
                </div>
              </div>
            </div>
            <div class="row g-0 border-bottom">
              <div class="row g-0 col-4 col-lg-2 bg-gray align-item-center">
                <label class="d-table-cell align-self-center">{{
                  $t("title.common.point_allocation")
                }}</label>
              </div>
              <div class="col-8 col-lg-10 d-flex">
                <p class="d-table-cell align-self-center">
                  <span>
                    {{ tournamentinfo.tour_class | tour_class }}
                    {{ $t("title.common.standard_point") }}
                    {{
                      tournamentinfo.oom_point == 1
                        ? "+ " + $t("title.common.oom_point")
                        : ""
                    }}
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div class="border-top-dgray mt-5">
            <!-- <div class="row g-0">
              <div
                class="col-lg-2 row g-0 bg-gray border-bottom align-items-center"
              >
                <label class="d-table-cell">{{
                  $t("title.common.location")
                }}</label>
              </div>
              <div class="col-lg-10 border-bottom">
                <p class="d-table-cell shop-name">
                  <span v-for="(shop, index2) in tournamentshop" :key="index2"
                    >{{ index2 === 0 ? "" : " / "
                    }}{{ shop.shopname || "Unregistered" }}</span
                  >
                </p>
              </div>
            </div> -->
            <div class="row g-0">
              <div
                class="col-lg-2 row g-0 bg-gray border-bottom align-items-center"
              >
                <label class="d-table-cell">{{
                  $t("title.common.prizes")
                }}</label>
              </div>
              <div class="col-lg-10 border-bottom">
                <div class="row g-0 border-bottom">
                  <div class="col-lg-12">
                    <p class="d-table-cell">
                      {{ tournamentprizeetc.etcprize || "Unregistered" }}
                    </p>
                  </div>
                </div>
                <div class="row g-0 border-bottom-0">
                  <div class="col-lg-12">
                    <p class="d-table-cell">
                      <span
                        class="d-block"
                        v-for="(rank, index3) in tournamentprizerank"
                        :key="index3"
                        ><strong class="fw-500 text-black"
                          >{{ $t("text.common.top") }}{{ rank.rankno }}</strong
                        >
                        :
                        <span class="text-prize">{{
                          rank.rankprize | comma
                        }}</span>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-0">
              <div
                class="col-lg-2 bg-gray row g-0 align-items-center border-bottom"
              >
                <label class="d-table-cell">{{
                  $t("title.common.tournament_conditions")
                }}</label>
              </div>
              <div class="col-lg-10">
                <div class="row g-0 border-bottom-0">
                  <div
                    class="col-lg-6 d-table-cell d-flex flex-column align-items-stretch"
                  >
                    <div class="row g-0 border-bottom">
                      <div
                        class="col-4 bg-lightgray row g-0 align-items-center"
                      >
                        <label class="d-table-cell">{{
                          $t("title.common.game_mode")
                        }}</label>
                      </div>
                      <div class="col-8 d-flex">
                        <p class="d-table-cell align-self-center">
                          {{ tournamentdetail.mode }}
                        </p>
                      </div>
                    </div>
                    <div class="row g-0 border-bottom">
                      <div class="col-4 bg-lightgray row g-0">
                        <label class="d-table-cell">{{
                          $t("title.common.level")
                        }}</label>
                      </div>
                      <div class="col-8 d-flex">
                        <p class="d-table-cell align-self-center">
                          {{ tournament_level(tournamentdetail.level) }}
                        </p>
                      </div>
                    </div>
                    <div class="row g-0 border-bottom">
                      <div
                        class="col-4 bg-lightgray row g-0 align-items-center"
                      >
                        <label class="d-table-cell">{{
                          $t("title.common.entry_limit")
                        }}</label>
                      </div>
                      <div class="col-8 d-flex">
                        <p class="d-table-cell align-self-center text-red">
                          {{ tournamentinfo.entrylimit | transToValue("∞") }}
                        </p>
                      </div>
                    </div>
                    <div
                      class="row g-0 border-bottom d-flex flex-row align-items-stretch h-100"
                    >
                      <div
                        class="col-4 bg-lightgray row g-0 align-items-center"
                      >
                        <label class="d-table-cell">{{
                          $t("title.common.mulligan")
                        }}</label>
                      </div>
                      <div class="col-8 d-flex">
                        <p class="d-table-cell align-self-center">
                          {{ tournamentdetail.mulligan }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-lg-6 d-table-cell d-flex flex-column align-items-stretch"
                  >
                    <div class="row g-0 border-bottom">
                      <div
                        class="col-4 bg-lightgray row g-0 align-items-center"
                      >
                        <label class="d-table-cell">{{
                          $t("title.common.play_format")
                        }}</label>
                      </div>
                      <div class="col-8 d-flex">
                        <p class="d-table-cell align-self-center">
                          {{ tournamentdetail.hole }}H
                        </p>
                      </div>
                    </div>
                    <div class="row g-0 border-bottom">
                      <div
                        class="col-4 bg-lightgray row g-0 align-items-center"
                      >
                        <label class="d-table-cell">{{
                          $t("title.common.wind")
                        }}</label>
                      </div>
                      <div class="col-8 d-flex">
                        <p class="d-table-cell align-self-center">{{ wind }}</p>
                      </div>
                    </div>
                    <div class="row g-0 border-bottom">
                      <div
                        class="col-4 bg-lightgray row g-0 align-items-center"
                      >
                        <label class="d-table-cell">{{
                          $t("title.tournament.rank_type")
                        }}</label>
                      </div>
                      <div class="col-8 d-flex">
                        <p class="d-table-cell align-self-center">
                          {{ tournamentinfo.tour_rank_type | tour_rank_type }}
                        </p>
                      </div>
                    </div>
                    <div class="row g-0 border-bottom">
                      <div
                        class="col-4 bg-lightgray row g-0 align-items-center"
                      >
                        <label class="d-table-cell">{{
                          $t("title.common.teebox")
                        }}</label>
                      </div>
                      <div class="col-8 d-flex">
                        <p class="d-table-cell align-self-center">
                          {{ $t("title.common.male") }} : {{ teemale }}<br />
                          {{ $t("title.common.female") }} : {{ teefemale }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="row g-0 border-bottom-0">
                  <div class="col-lg-3 bg-gray row align-items-center">
                    <label class="d-table-cell">Handicap(No. of Play)</label>
                  </div>
                  <div class="col-lg-9">
                    <p class="d-table-cell">
                      {{ tournamentdetail.playhandicap }}
                    </p>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="btn-bottom-wrapper">
          <b-button
            size="xl"
            variant="outline-primary"
            :aria-label="$t('button.common.list')"
            @click="click_list()"
            >{{ $t("button.common.list") }}</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import tab from "@/components/membership/Tab";
import ax from "@/api/membership";
import "@/filter/common";
import { myMixin } from "@/mixin/myMixin";

export default {
  name: "MyTournamentsView",
  components: {
    location,
    tab,
  },
  data() {
    return {
      tournamentno: this.$route.params.id,
      tournamentinfo: [
        {
          title: "",
          remark: "",
          startdate: "",
          enddate: "",
        },
      ],
      tournamentcourse: [],
      tournamentshop: [],
      tournamentprizeetc: [
        {
          etcprize: "",
        },
      ],
      tournamentprizerank: [],
      tournamentdetail: [
        {
          mode: "",
          hole: "",
          level: "",
          wind: "",
          mulligan: "",
          teeboxman: "",
          teeboxwoman: "",
          playhandicap: "",
        },
      ],
      wind: "",
      teemale: "",
      teefemale: "",
      subcourselist: [],
      tourbinary: [],
      subcourselistcnt: 0,
    };
  },
  methods: {
    get_membertournamentcondition() {
      ax.get_membertournamentcondition(this.tournamentno, (flag, data) => {
        if (flag) {
          this.tournamentinfo = data.tournamentinfo;
          this.tournamentcourse = data.tournamentcourse;
          this.tournamentshop = data.tournamentshop;
          this.tournamentprizeetc = data.tournamentprizeetc;
          this.tournamentprizerank = data.tournamentprizerank;
          this.tournamentdetail = data.tournamentdetail;
          this.subcourselist = data.subcourselist;
          this.subcourselistcnt = data.subcourselistcnt;
          this.tourbinary = data.tourbinary;
          this.change_wind(this.tournamentdetail.wind);
          this.change_male(this.tournamentdetail.teeboxman);
          this.change_female(this.tournamentdetail.teeboxwoman);
        } else {
          alert(data);
        }
      });
    },
    click_list() {
      this.$router.push({
        name: "MyTournaments",
        query: {
          startdate: this.$route.query.startdate,
          enddate: this.$route.query.enddate,
        },
      });
    },
    //   change_wind(wind) {
    //     if (wind === "0") {
    //       this.wind = this.$i18n.t("text.common.calm");
    //     } else if (wind === "1") {
    //       this.wind = this.$i18n.t("text.common.light");
    //     } else if (wind === "2") {
    //       this.wind = this.$i18n.t("text.common.moderate");
    //     } else if (wind === "3") {
    //       this.wind = this.$i18n.t("text.common.strong");
    //     } else if (wind === "4") {
    //       this.wind = this.$i18n.t("text.common.random");
    //     } else {
    //       this.wind = wind;
    //     }
    //   },
    //   change_male(teeboxman) {
    //     if (teeboxman === "1") {
    //       this.teemale =
    //         this.$i18n.t("text.common.pro") +
    //         "(" +
    //         this.$i18n.t("text.common.blue") +
    //         ")";
    //     } else if (teeboxman === "2") {
    //       this.teemale =
    //         this.$i18n.t("text.common.regular") +
    //         "(" +
    //         this.$i18n.t("text.common.white") +
    //         ")";
    //     } else if (teeboxman === "3") {
    //       this.teemale =
    //         this.$i18n.t("text.common.lady") +
    //         "(" +
    //         this.$i18n.t("text.common.red") +
    //         ")";
    //     } else {
    //       this.teemale = teeboxman;
    //     }
    //   },
    //   change_female(teeboxwoman) {
    //     if (teeboxwoman === "1") {
    //       this.teefemale =
    //         this.$i18n.t("text.common.pro") +
    //         "(" +
    //         this.$i18n.t("text.common.blue") +
    //         ")";
    //     } else if (teeboxwoman === "2") {
    //       this.teefemale =
    //         this.$i18n.t("text.common.regular") +
    //         "(" +
    //         this.$i18n.t("text.common.white") +
    //         ")";
    //     } else if (teeboxwoman === "3") {
    //       this.teefemale =
    //         this.$i18n.t("text.common.lady") +
    //         "(" +
    //         this.$i18n.t("text.common.red") +
    //         ")";
    //     } else {
    //       this.teefemale = teeboxwoman;
    //     }
    //   },
  },
  created() {
    this.get_membertournamentcondition();
  },
  mixins: [myMixin],
};
</script>
